import { SystemStyleFunction } from '@chakra-ui/theme-tools'

const FPIVariant: SystemStyleFunction = (props) => {
    return {
        '.header-banner': {
            backgroundImage: {
                base: '/images/sielbleu/pfandme/header_image_webmobile.jpg',
                sm: '/images/sielbleu/pfandme/header_image.jpg',
            },
        },
    }
}

const BPCOVariant: SystemStyleFunction = (props) => {
    return {
        '.header-banner': {
            backgroundImage: {
                base: '/images/sielbleu/bpco/header_image_webmobile.jpg',
                sm: '/images/sielbleu/bpco/header_image.jpg',
            },
        },
    }
}

const sielBleuAtWorkVariant: SystemStyleFunction = (props) => {
    return {
        '.header-banner': {
            backgroundImage: {
                base: '/images/sielbleu/SielBleuAtWork/header_image_webmobile.jpg',
                sm: '/images/sielbleu/SielBleuAtWork/header_image.jpg',
            },
        },
    }
}

const respifilVariant: SystemStyleFunction = () => {
    return {
        '.header-banner': {
            backgroundImage: {
                base: '/images/sielbleu/respifil-apa/header_image_webmobile.jpg',
                sm: '/images/sielbleu/respifil-apa/header_image.jpg',
            },
        },
    }
}

const genericVariant: SystemStyleFunction = () => {
    return {
        '.header-banner': {
            backgroundImage: {
                base: '/images/sielbleu/genericPlatform/Visuel_header_01_webmobile@2x.jpg',
                sm: '/images/sielbleu/genericPlatform/Visuel_header_01_web@2x.jpg',
            },
            minHeight: '500px',
            justifyContent: { base: 'flex-end', md: 'center' },
            p: '0px',
            '.header-text': {
                textAlign: 'left',
                '.main-title': {
                    fontSize: { base: '35px' },
                },
            },
        },
        '.section': {
            lineHeight: '2!',
            color: '#000000!',
            mb: '20px',
            '.highlight': {
                color: 'brand.medium',
            },
        },
        '.insert': {
            backgroundColor: 'brand.extraLight',
            padding: '20px',
            mb: '20px',
            borderRadius: '10px',
            lineHeight: '2!',
        },
        '.section-title': {
            position: 'relative',
        },
        '.section-title:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: {
                base: '124px',
                lg: '185px',
            },
            height: '50px',
            bottom: {
                base: '-25px',
                lg: '-38px',
            },
            backgroundImage: '/images/sielbleu/petits-pas/soulignement.png',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom',
        },
        //Above css is only for the blocs colors
        '.container': {
            w: '100%',
            maxW: '100%',
            px: '0px',
            margin: '0px',
            mx: '0',
            mb: {
                lg: '0px',
            },
        },
        '.description': {
            py: {
                base: '15px',
                md: '30px',
            },
            px: {
                md: '70px',
            },
            w: '100%',
            alignItems: 'center',
            div: {
                maxW: { md: '1000px !important' },
            },
            img: {
                w: {
                    md: '100% !important',
                },
            },
        },
        '.input-container': {
            display: 'flex',
            flexWrap: {
                base: 'wrap',
                md: 'nowrap',
            },
            justifyContent: 'center',
            '.button': {
                margin: { base: '10px', md: '0px' },
            },
        },
        '.description:nth-child(even)': {
            backgroundColor: 'brand.extraLight',
        },
        '.description-text': {
            marginX: '14px',
            marginY: '15px',
            '.section-title': {
                marginTop: '0px',
            },
            marginBottom: { lg: '55px' },
            maxW: { base: '345px', md: '1000px !important', '2xl': '1400px !important' },
            margin: 'auto',
            '.card-blue': {
                w: {
                    xl: '416px',
                },
                bg: '#FFFFFF',
            },
        },
        '.support': {
            pt: {
                base: '45px',
                md: '65px',
            },
            pb: { base: '45px', lg: '15px' },
            px: {
                md: '70px',
            },
            w: '100%',
            alignItems: 'center',
            div: {
                maxW: { base: '345px', md: '1000px !important', '2xl': '1400px !important' },
            },
            margin: 'auto',
            img: {
                w: {
                    md: '100% !important',
                },
            },
        },
    }
}
const petitsPasVariant: SystemStyleFunction = () => {
    return {
        '.header-banner': {
            backgroundImage: {
                base: '/images/sielbleu/petits-pas/Visuel_header_01_webmobile.jpg',
                sm: '/images/sielbleu/petits-pas/Visuel_header_01_web.jpg',
            },
            minHeight: '600px',
            maxHeight: '680px',
            alignItems: 'start',
            '.header-text': {
                marginX: {
                    base: '5px',
                    lg: '70px',
                },
                textAlign: 'left',
                textShadow: '0px 3px 6px #000000',
                '.main-title': {
                    fontSize: { base: '35px' },
                },
            },
        },
        '.section': {
            lineHeight: '2!',
            color: '#000000!',
            mb: '20px',
        },
        '.section-title': {
            position: 'relative',
        },
        '.section-title:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: {
                base: '124px',
                lg: '185px',
            },
            height: '50px',
            bottom: {
                base: '-25px',
                lg: '-38px',
            },
            backgroundImage: '/images/sielbleu/petits-pas/soulignement.png',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom',
        },
        //Above css is only for the blocs colors
        '.container': {
            w: '100%',
            maxW: '100%',
            px: '0px',
            margin: '0px',
            mx: '0',
            mb: {
                lg: '0px',
            },
        },
        '.description': {
            py: {
                base: '15px',
                md: '30px',
            },
            px: {
                md: '70px',
            },
            w: '100%',
            alignItems: 'center',
            div: {
                maxW: { md: '900px !important' },
            },
            img: {
                w: {
                    md: '100% !important',
                },
            },
        },
        '.input-container': {
            display: 'flex',
            flexWrap: {
                base: 'wrap',
                md: 'nowrap',
            },
            mb: '17px',
            justifyContent: 'center',
            '.button': {
                margin: { base: '10px', md: '0px' },
            },
        },
        '.description:nth-child(even)': {
            backgroundColor: 'brand.extraLight',
        },
        '.description-text': {
            marginX: '14px',
            marginY: '15px',
            '.section-title': {
                marginTop: '0px',
            },
            maxW: { base: '345px', md: '1000px !important', '2xl': '1400px !important' },
            margin: 'auto',
            '.carousel-container': {
                my: '20px !important',
                margin: 'auto',
                maxWidth: '100%',
                '.slide': {
                    backgroundColor: 'brand.extraLight',
                    borderRadius: '20px',
                    p: '20px',
                    mb: '54px',
                },
                '.carousel-pic': {
                    w: {
                        base: '100%',
                        lg: '25%',
                    },
                    ml: {
                        base: 'auto',
                        lg: '15px',
                    },
                    mr: {
                        base: 'auto',
                        lg: '25px',
                    },
                    mb: {
                        base: '20px',
                        lg: '15px',
                    },
                    mt: { lg: '15px' },
                    maxW: {
                        base: '498px',
                        md: '400px',
                    },
                },
                '.carousel-content': {
                    textAlign: 'left',
                    position: 'relative',
                    w: {
                        base: '100%',
                        lg: '75%',
                    },
                },
                img: {
                    borderRadius: '20px',
                    w: '100%',
                },
                '.carousel-title': {
                    color: 'brand.heavy',
                    fontSize: '25px',
                    fontWeight: '550',
                    mb: '10px',
                },
                '.carousel-subtitle': {
                    color: 'brand.heavy',
                    mb: '10px',
                },
                '.carousel-text': {
                    lineHeight: '2',
                },
                '.carousel-quote': {
                    backgroundImage: '/images/sielbleu/petits-pas/icon_quote.png',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: {
                        base: '64px',
                        lg: '89px',
                    },
                    backgroundPosition: '0 0',
                },
                '.slider-wrapper': {
                    w: {
                        base: '100%',
                        lg: 'calc(100% - 150px)',
                    },
                },
            },
            '.custom-arrow': {
                width: '42px',
                borderRadius: '50%',
                border: 'none',
                borderColor: 'gray.200',
                backgroundColor: 'brand.extraLight',
                position: 'absolute',
                bottom: {
                    base: '-20px',
                    lg: '50%',
                },
                transform: 'translate(0, -50%)',
                zIndex: '1',
                cursor: 'pointer',
            },
            '.control-dots': {
                display: 'flex',
                justifyContent: 'center',
            },
            '.custom-dot': {
                w: '14px',
                h: '14px',
                borderRadius: '50%',
                zIndex: '1',
                cursor: 'pointer',
                mx: '6px',
            },
            '.cta': {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '18px',
                margin: 'auto',
                mb: {
                    base: '18px',
                    md: '36px',
                },
                mt: {
                    base: '18px',
                    md: '24px',
                },
            },
            '.card-container': {
                marginTop: '15px',
                margin: 'auto',
            },
            '.card': {
                w: '100%',
                mb: { base: '25px' },
                backgroundColor: 'brand.extraLight',
                borderRadius: '20px',
                lineHeight: '2',
            },
            '.card-blue': {
                w: {
                    base: 'initial',
                    lg: '100%',
                },
                p: {
                    lineHeight: '1.5 !important',
                },
            },
        },
        '.support': {
            pb: {
                base: '45px',
                md: '65px',
            },
            px: {
                md: '70px',
            },
            w: '100%',
            alignItems: 'center',
            div: {
                maxW: { base: '345px', md: '1000px !important', '2xl': '1400px !important' },
            },
            margin: 'auto',
            img: {
                w: {
                    md: '100% !important',
                },
            },
        },
    }
}
const variants = {
    FPI: FPIVariant,
    BPCO: BPCOVariant,
    atWork: sielBleuAtWorkVariant,
    RESPIFIL: respifilVariant,
    PETITSPAS: petitsPasVariant,
    GENERIC: genericVariant,
}

const baseStyle = {
    '.header-banner': {
        padding: {
            base: '16px',
        },
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        backgroundColor: 'grey.extraLight',
        h: {
            base: '457px',
            lg: '732px',
        },
        h2: {
            color: 'white',
        },
        '.main-title': {
            fontSize: {
                lg: '50px',
            },
        },
        '.motivation-title': {
            fontSize: {
                lg: '40px',
            },
            fontWeight: 400,
            my: {
                base: '16px',
                md: '25px',
            },
        },
    },
    '.container': {
        mx: 'auto',
        mb: {
            base: '55px',
            lg: '75px',
        },
        px: {
            md: '50px',
        },
        maxW: {
            base: '345px',
            md: '100%',
            '2xl': '1400px',
        },
        '.section-title': {
            mt: {
                base: '55px',
                lg: '75px',
            },
            mb: {
                base: '36px',
                lg: '56px',
            },
        },
        '.section': {
            color: 'brand.extraHeavy',
            fontSize: '17px',
            lineHeight: '7',
        },
        '.description': {
            '.description-text': {
                mt: {
                    lg: '55px',
                },
            },
            display: {
                lg: 'flex',
            },
            img: {
                m: '0 auto',
                w: {
                    base: '100%',
                    md: '486px',
                },
            },
            div: {
                maxW: {
                    md: '800px',
                },
            },
        },
        '.card-container': {
            display: {
                xl: 'flex',
            },
            justifyContent: {
                xl: 'space-between',
            },
            columnGap: {
                xl: '35px',
            },
        },
        '.card-blue': {
            w: {
                xl: '416px',
            },
            bg: 'brand.extraLight',
            textAlign: 'center',
            p: '30px',
            borderRadius: '20px',
            h2: {
                my: '15px',
            },
            mb: '20px',
        },
        '.card-white': {
            mb: '50px',
            w: {
                xl: '720px',
            },
            a: {
                textDecoration: 'underline',
                _hover: {
                    opacity: 0.5,
                },
            },
            '.one': {
                backgroundImage: {
                    base: '/images/sielbleu/landing/bloc_01_webmobile.jpg',
                    md: '/images/sielbleu/landing/bloc_01_web.jpg',
                },
            },
            '.two': {
                backgroundImage: {
                    base: '/images/sielbleu/landing/bloc_02_webmobile.jpg',
                    md: '/images/sielbleu/landing/bloc_02_web.jpg',
                },
            },
            '.card-white-title': {
                h: '232px',
                p: {
                    base: '5px',
                    md: '20px',
                },
                borderRadius: '10px 10px 0 0',
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                verticalAlign: 'middle',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                backgroundColor: 'brand.light',
                mb: '20px',
                h2: {
                    color: 'white',
                    fontWeight: 550,
                    fontSize: '22px',
                    lineHeight: '8',
                },
            },
        },
        '.funded': {
            my: {
                base: '15px',
                md: '30px',
            },
            display: 'flex',
            flexDirection: {
                base: 'column',
                md: 'row',
            },
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '15px',
            columnGap: '15px',
        },
        '.card-full-blue': {
            mt: '55px',
            bg: 'brand.medium',
            padding: '22px',
            borderRadius: '20px',
            textAlign: 'center',
            color: 'white',
            p: {
                color: 'white',
                fontWeight: 500,
            },
            h2: {
                color: 'white',
                mb: '22px',
                fontSize: {
                    base: '20px',
                    lg: '28px',
                },
            },
            button: {
                color: 'brand.medium',
                bg: 'white',
                mt: '22px',
                _hover: {
                    bg: '#A2D0ED',
                },
            },
        },
        '.carousel a': {
            display: 'block',
        },
        '.carousel .slider-wrapper': {
            width: '80%',
        },
        '.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover': {
            backgroundColor: 'transparent',
        },
        '.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover': {
            backgroundColor: 'transparent',
        },
        '.carousel .control-next.control-arrow:before': {
            content: '""',
            border: 'solid grey',
            borderWidth: '0 4px 4px 0',
            display: 'inline-block',
            padding: '14px',
            transform: 'rotate(-45deg)',
        },
        '.carousel .control-prev.control-arrow:before': {
            content: '""',
            border: 'solid grey',
            borderWidth: '0 4px 4px 0',
            display: 'inline-block',
            padding: '14px',
            transform: 'rotate(135deg)',
        },
    },
}

const Landing = {
    baseStyle,
    variants,
}
export default Landing
