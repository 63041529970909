export const routes = {
    public: {
        root: '/',
        register: '/register',
        login: '/login',
        forgotPassword: '/forgot-password',
        resetPassword: '/reset-password',
        contact: '/contact',
        logout: '/logout',
        prompt: '/prompt',
    },
    connected: {
        settingsNewsletter: '/settings/newsletter',
        settingsEmail: '/settings/email',
        settingsName: '/settings/name',
        settingsBirthdate: '/settings/birthdate',
        settingsGender: '/settings/gender',
        settingsAddress: '/settings/address',
        settingsForgotPwd: '/settings/forgot-password',
        settingsLocale: '/settings/locale',
        settingsAudio: '/settings/audio',
        settings: '/settings',
        authorize: '/authorize',
        adaptedSessions: '/adapted-sessions',
        adaptedSessionWorkout: '/adapted-sessions/workout',
        calendar: '/calendar/[date]',
        liveSessions: '/live-sessions',
        liveSessionsCancel: '/live-sessions/cancel/[id]',
        singleSessions: '/single-sessions',
        singleSessionWorkout: '/single-sessions/workout',
        videoSessions: '/video-sessions',
        videoSessionWorkout: '/video-sessions/workout',
        medicalConsent: '/medical-consent',
        emailVerify: '/email-verify',
        emailVerifyRequest: '/email-verify-request',
        acceptTerms: '/accept-terms',
        statistics: '/statistics',
        completedPrograms: '/completed-programs',
        product: '/product',
        checkoutReturn: '/checkout/return',
    },
    api: {
        login: '/api/sielbleu/login',
        logout: '/api/sielbleu/logout',
        signup: '/api/sielbleu/signup',
        refresh: '/api/sielbleu/refresh',
        recaptcha: '/api/sielbleu/recaptcha',
        loginSsoUrl: '/api/sielbleu/login-sso-url',
        updateUserProfile: '/api/sielbleu/update-user-profile',
        updateProfile: '/api/sielbleu/update-profile',
        me: '/api/sielbleu/me',
        acceptTerms: '/api/sielbleu/accept-terms',
        emailVerify: '/api/sielbleu/email-verify',
    },
}
